.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 20vh;
  width: 75%;
  margin: auto;
}

.title {
  padding: var(--mb-2);
}

.title > h2 {
  font-size: 5rem;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.formHeader {
  font-size: var(--mb-3);
}

.infoLink {
  display: none;
}

.formContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: var(--mb-6);
}

.recaptcha {
  display: inline-block !important;
}

.button {
  font-size: larger;
  width: fit-content;
  padding: var(--mb-2);
  margin: var(--mb-2);
  background: var(--white);
  transition: 0.3s all ease-in-out;
  border-radius: var(--mb-2);
  color: black;
  cursor: pointer;
}

.button:hover {
  background: var(--lightBlue);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .contentContainer {
    flex-direction: column;
    align-items: center;
  }

  .infoLink {
    display: unset;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: 15vh;
    width: 100%;
  }

  .title > h2 {
    font-size: 3rem;
  }
}
