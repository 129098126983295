.hero {
   /* background-image: url('../../../public/hero-bg.png'); */
   background-size: cover;
   height: 100vh;
   padding: 3rem 2rem;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.heroTextContainer {
   margin-bottom: 8rem;
}

.heroText {
   color: var(--secondary);
   font-size: 5rem;
   text-transform: uppercase;
   margin-bottom: 2rem;
}

.heroSubtext {
   color: var(--secondary);
   font-size: 3rem;
   margin-bottom: 3rem;
}

.peaks {
   width: 100%;
   position: absolute;
   bottom: 0;
}

@media screen and (max-width: 1170px) {
   .heroTextContainer {
      margin-bottom: 0rem;
   }
}
