/*===== CSS VARIABLES =====*/

/* Font Size */
:root {
  --normalFont: 16px;
}

/* Colors */
:root {
  --white: #e1e2e2;
  --bgBlue: #0d192a;
  --lightBlue: #4361cd;
  --lightGrey: #cdcdce;
}

/* Fonts */
:root {
  --primaryFont: "PrimaryFont";
}

/* Margins */
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/*===== z index =====*/
:root {
  --z-back: -10;
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

body {
  font-family: var(--primaryFont);
  font-size: var(--normalFont);
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

