.nav {
  background-color: rgba(217, 217, 217, 0.95);
  width: 100vw;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.logo {
  height: auto;
  width: 10rem;
}

.nav_toggle {
  display: none;
  position: absolute;
  right: 1rem;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  z-index: 101;
}

.nav_toggle span,
.nav_toggle span:before,
.nav_toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 4px;
  width: 30px;
  background: var(--primary);
  position: absolute;
  display: block;
  content: "";
  transition: all 300ms ease-in-out;
}

.nav_toggle span:before {
  top: -10px;
}
.nav_toggle span:after {
  bottom: -10px;
}

.nav_toggle.active span {
  background-color: transparent;
}

.nav_toggle.active span:before,
.nav_toggle.active span:after {
  top: 0;
}
.nav_toggle.active span:before {
  transform: rotate(45deg);
}
.nav_toggle.active span:after {
  transform: rotate(-45deg);
}

.navLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navItems {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  text-transform: uppercase;
  margin: auto;
}

.navItem {
  color: var(--primary);
  margin: 0 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}

.navLink {
  font-weight: 600;
  padding-bottom: 2px;
}

.navLink:hover {
  color: var(--bg);
  border-bottom: 3px solid var(--primary);
}

.navLink:active,
.navLink:link,
.navLink:visited,
.navLink:focus {
  color: var(--primary);
  text-decoration: none;
}

.navLink.active {
  color: #fff;
  background-color: var(--primary);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.navLink.active:hover {
  border-bottom: none;
}

.navButton {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 0.5rem;
  padding: 0.5rem 1.4rem;
  border: none;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: var(--primary);
  color: var(--primary);
  text-transform: uppercase;
  border: 2px solid var(--primary);
  cursor: pointer;
}

.navButton_mobile {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 0.5rem;
  padding: 0.5rem 1.4rem;
  border: none;
  font-weight: 600;
  font-size: 0.8rem;
  font-family: var(--primary);
  color: var(--primary);
  text-transform: uppercase;
  border: 2px solid var(--primary);
  cursor: pointer;
}

.menuBtn {
  display: none;
}

.nav_mobile {
  display: none;
  height: 100vh;
  width: 50vw;
  padding: 3rem 1rem;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: rgba(217, 217, 217, 0.9);
  transition: all 0.5s ease;
  transform: translateX(900px);
}

.activeMob {
  transform: translateX(0);
}

.navItems_mobile {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
  padding-top: 2rem;
}

.navItem_mobile {
  /* margin-block: 1rem; */
  padding: 1rem;
}

@media only screen and (min-width: 1000px) and (max-width: 1023px) {
  .navItem {
    margin: 0 0.8rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .navButton {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 999px) {
  .nav_mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .navItems_mobile {
    display: flex;
  }

  .nav_toggle {
    display: block;
  }
  .nav {
    padding: 0 1rem 0rem 0rem;
  }
  .logo {
    width: 8rem;
  }

  .navItems {
    display: none;
  }

  .menuBtn {
    color: var(--primary);
    display: block;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .navButton {
    display: none;
  }

  .nav-mobile {
    display: block;
  }
  .navLinks {
    width: 100%;
    padding: 70px 0 15px;
  }
  .navLinks ul {
    display: none;
  }
  .navLinks ul li {
    float: none;
  }
  .navLinks ul li a {
    padding: 15px;
    line-height: 20px;
  }
  .navLinks ul li ul li a {
    padding-left: 30px;
  }
  .nav-dropdown {
    position: static;
  }
}
