.wrapper {
  padding: var(--mb-6);
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.infoDocName {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: var(--mb-4);
}

.infoDocName > img {
  height: var(--mb-4);
}

.infoDocButton {
  font-size: larger;
  width: fit-content;
  padding: var(--mb-2);
  margin: var(--mb-2);
  background: var(--white);
  transition: 0.3s all ease-in-out;
  border-radius: var(--mb-2);
  color: black;
  cursor: pointer;
  animation: pulse 2s infinite ease-in-out;
}

.infoDocButton:hover {
  background: var(--lightBlue);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 1px rgba(255, 255, 255, 1);
  }
  25% {
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.9);
  }
  50% {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.7);
  }
  75% {
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.4);
  }
  100% {
    box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  }
}

.infoHeading {
  font-size: xx-large;
  margin-top: 10px;
}

.section {
  text-align: left;
  margin: var(--mb-2);
  padding: var(--mb-2) var(--mb-6);
  backdrop-filter: blur(8px);
}

.sectionHeading {
  font-size: x-large;
  padding: var(--mb-1);
}

.sectionList {
  margin-top: 10px;
  font-size: large;
}

.sectionListItem {
  list-style-type: square;
}

.sectionListItemPrereq {
  list-style-type: circle;
  position: relative;
  left: 20px;
}

.PrereqURL {
  text-decoration: underline;
  margin-left: 2px;
}

.PrereqURL:hover {
  cursor: pointer;
}
/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .infoDocName {
    align-items: center;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    padding-top: var(--mb-6);
  }

  .section {
    padding: var(--mb-2);
  }

  .infoDocName {
    font-size: var(--mb-2);
  }
}
