@import url("https://fonts.googleapis.com/css2?family=Changa&family=Nunito+Sans:ital@1&family=Oxanium&family=Roboto:wght@700&family=Urbanist:wght@500&display=swap");
.title {
  font-size: 5rem;
  color: rgba(217, 217, 217, 0.55);
  text-shadow: 12px 20px 10px rgba(47, 44, 44, 0.25);
  padding-top: 20vh;
  padding-bottom: 4%;
}
.desc {
  font-size: var(--mb-3);
  color: #ffffff;
  width: 70%;
  margin: auto;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px);
  text-align: justify;
  font-weight: 100;
}
.learn_more_button {
  box-sizing: border-box;
  background: #a9003a;
  opacity: 0.44;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5px;
  margin: 5px;
}
.learn_more_text {
  font-size: 1.5vw;
  color: #ffffff;
}
.about_us_box {
  width: 70%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #d9d9d9;
  opacity: 0.7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 72px 15px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  padding: 15px;
}
.about_us_title {
  font-size: 4vw;
  padding: 10px;
  background: linear-gradient(
    90deg,
    #ff758c -0.98%,
    rgba(254, 33, 33, 0.4) 104.08%,
    #fc78ae 104.08%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.about_us_desc {
  font-size: 1.8vw;
  line-height: 130%;
  color: #000000;
}
.card_box {
  background: #d9d9d9;
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 4px 72px 15px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  width: 70%;
  margin: auto;
  padding: 8px;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.card_desc {
  width: 60%;
  color: #000000;
  margin: auto;
  padding: 10px;
}
.card_img {
  width: 60%;
  height: 80%;
  margin: auto;
  background: #d9d9d9;
  border-radius: 28px;
}
.card_img_div {
  width: 25%;
  margin: auto;
}
.card_desc_title {
  font-size: 2vw;
}
.card_desc_desc {
  text-align: left;
  font-size: 1.4vw;
  padding: 15px;
}
.karnivalMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.karnivalTypeCard {
  margin: 3%;
  width: 350px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  text-decoration: none;
  color: var(--text);
}

.karnivalTypeYearWiseImage {
  width: 100%;
  object-fit: cover;
  height: 250px;
  display: block;
  margin: 20px 0;
}

.karnivalTypeTitle {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text);
  padding-bottom: 1.6%;
}

.karnivalTypeDescription {
  text-align: center;
  width: 90%;
  margin: auto;
  padding: 20px;
  text-align: justify;
  text-decoration: none;
  color: var(--text);
}

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .card_img_div {
    width: 35%;
    margin: auto;
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .desc {
    width: 80%;
    font-size: var(--mb-2);
  }
  .card_img_div {
    width: 200px;
    margin: auto;
  }
  .about_us_desc {
    font-size: 2.5vw;
  }
  .card_desc_title {
    font-size: 3vw;
  }
  .card_desc_desc {
    font-size: 2.5vw;
  }
}
/* Mobile Only*/
@media only screen and (max-width: 767px) {
  .card_img_div {
    width: 150px;
    margin: auto;
  }
  .about_us_box {
    width: 90%;
  }
  .card_box {
    width: 90%;
  }
  .about_us_desc {
    font-size: 2.5vw;
  }
  .card_desc_title {
    font-size: 3vw;
  }
  .card_desc_desc {
    font-size: 2.5vw;
  }
  .card_desc {
    width: 100%;
  }
  .karnivalTypeCard {
    margin-bottom: 20%;
  }
  .title {
    padding-top: 27%;
  }
}
