.formField {
  margin: var(--mb-2) 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  width: 100%;
}

.formField:nth-child(n) {
  background: linear-gradient(#262626, #262626) padding-box,
    linear-gradient(to left, var(--lightBlue), var(--lightGrey)) border-box;
}

.formField > input,
.formField > select,
.formField > .phone,
.formField > textarea {
  padding: var(--mb-2) 0;
  padding-right: var(--mb-2);
  border: none;
  background: none;
  outline: none;
  font-size: 1rem;
  color: var(--white);
  width: 275px;
}

.formField .PhoneInputInput {
  border: none;
  background: none;
  outline: none;
  font-size: 1rem;
  color: var(--white);
}

.formField > select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
  padding: var(--mb-2);
}

.formField > select > option {
  background-color: var(--bgBlue);
  font-size: 1.1rem;
  /* margin: var(--mb-6); */
  text-align: left;
}

.textarea {
  resize: vertical;
  font-family: var(--normalFont);
  height: 150px;
  max-height: 250px;
}

.info {
  font-size: 0.8rem;
  text-align: left !important;
  opacity: 0.5;
}

.selectDiv {
  position: relative;
}

.selectDiv::after {
  content: "▼";
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 8px 0px;
  position: absolute;
  font-weight: 900;
  cursor: pointer;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text);
}

.formFieldIcon {
  width: 25px;
  margin: 0 var(--mb-2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .formField > input,
  .formField > select {
    width: 250px;
  }
}
