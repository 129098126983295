.outerbody {
    background-attachment: fixed;
    position: fixed;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    /* z-index: -1; */
  }
  
  .stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform: rotate(-30deg);
  }
  
  .star {
    --star-color: var(--primary-color);
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
  }
  @media screen and (max-width: 750px) {
    .star {
      animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }
  }
  .star:nth-child(1) {
    --star-tail-length: 5.55em;
    --top-offset: 2.47vh;
    --fall-duration: 9.925s;
    --fall-delay: 1.55s;
  }
  .star:nth-child(2) {
    --star-tail-length: 6.72em;
    --top-offset: 1.89vh;
    --fall-duration: 7.304s;
    --fall-delay: 9.521s;
  }
  .star:nth-child(3) {
    --star-tail-length: 6.92em;
    --top-offset: 84.32vh;
    --fall-duration: 6.597s;
    --fall-delay: 9.133s;
  }
  .star:nth-child(4) {
    --star-tail-length: 5.33em;
    --top-offset: 61.14vh;
    --fall-duration: 6.927s;
    --fall-delay: 6.162s;
  }
  .star:nth-child(5) {
    --star-tail-length: 7.18em;
    --top-offset: 60.56vh;
    --fall-duration: 6.015s;
    --fall-delay: 7.42s;
  }
  .star:nth-child(6) {
    --star-tail-length: 5.45em;
    --top-offset: 75.84vh;
    --fall-duration: 9.132s;
    --fall-delay: 9.111s;
  }
  .star:nth-child(7) {
    --star-tail-length: 6.48em;
    --top-offset: 28.59vh;
    --fall-duration: 7.875s;
    --fall-delay: 5.457s;
  }
  .star:nth-child(8) {
    --star-tail-length: 6.5em;
    --top-offset: 80.15vh;
    --fall-duration: 11.899s;
    --fall-delay: 4.04s;
  }
  .star:nth-child(9) {
    --star-tail-length: 6.26em;
    --top-offset: 36.34vh;
    --fall-duration: 6.552s;
    --fall-delay: 3.571s;
  }
  .star:nth-child(10) {
    --star-tail-length: 7.08em;
    --top-offset: 56.5vh;
    --fall-duration: 10.297s;
    --fall-delay: 6.414s;
  }
  .star:nth-child(11) {
    --star-tail-length: 5.43em;
    --top-offset: 92.94vh;
    --fall-duration: 8.66s;
    --fall-delay: 9.325s;
  }
  .star:nth-child(12) {
    --star-tail-length: 7.18em;
    --top-offset: 14.01vh;
    --fall-duration: 9.774s;
    --fall-delay: 5.049s;
  }
  .star:nth-child(13) {
    --star-tail-length: 6.74em;
    --top-offset: 90.3vh;
    --fall-duration: 11.694s;
    --fall-delay: 7.268s;
  }
  .star:nth-child(14) {
    --star-tail-length: 5.2em;
    --top-offset: 93.06vh;
    --fall-duration: 10.671s;
    --fall-delay: 9.151s;
  }
  .star:nth-child(15) {
    --star-tail-length: 6.46em;
    --top-offset: 70vh;
    --fall-duration: 8.863s;
    --fall-delay: 4.895s;
  }
  .star:nth-child(16) {
    --star-tail-length: 6.73em;
    --top-offset: 92.95vh;
    --fall-duration: 6.965s;
    --fall-delay: 3.535s;
  }
  .star:nth-child(17) {
    --star-tail-length: 7.17em;
    --top-offset: 32.69vh;
    --fall-duration: 11.626s;
    --fall-delay: 9.327s;
  }
  .star:nth-child(18) {
    --star-tail-length: 6.4em;
    --top-offset: 73.79vh;
    --fall-duration: 6.351s;
    --fall-delay: 0.06s;
  }
  .star:nth-child(19) {
    --star-tail-length: 7.38em;
    --top-offset: 31.57vh;
    --fall-duration: 10.876s;
    --fall-delay: 0.533s;
  }
  .star:nth-child(20) {
    --star-tail-length: 5.73em;
    --top-offset: 66.23vh;
    --fall-duration: 9.947s;
    --fall-delay: 5.399s;
  }
  .star:nth-child(21) {
    --star-tail-length: 6.88em;
    --top-offset: 3.4vh;
    --fall-duration: 11.083s;
    --fall-delay: 7.143s;
  }
  .star:nth-child(22) {
    --star-tail-length: 6.26em;
    --top-offset: 81.64vh;
    --fall-duration: 11.746s;
    --fall-delay: 2.787s;
  }
  .star:nth-child(23) {
    --star-tail-length: 6.63em;
    --top-offset: 28.33vh;
    --fall-duration: 10.832s;
    --fall-delay: 1.024s;
  }
  .star:nth-child(24) {
    --star-tail-length: 5.5em;
    --top-offset: 35.67vh;
    --fall-duration: 8.291s;
    --fall-delay: 0.393s;
  }
  .star:nth-child(25) {
    --star-tail-length: 6.24em;
    --top-offset: 12.09vh;
    --fall-duration: 11.58s;
    --fall-delay: 2.363s;
  }
  .star:nth-child(26) {
    --star-tail-length: 5.97em;
    --top-offset: 8.04vh;
    --fall-duration: 10.089s;
    --fall-delay: 4.201s;
  }
  .star:nth-child(27) {
    --star-tail-length: 6.86em;
    --top-offset: 80.21vh;
    --fall-duration: 9.024s;
    --fall-delay: 0.543s;
  }
  .star:nth-child(28) {
    --star-tail-length: 6.83em;
    --top-offset: 46.51vh;
    --fall-duration: 9.488s;
    --fall-delay: 6.746s;
  }
  .star:nth-child(29) {
    --star-tail-length: 6.3em;
    --top-offset: 30.48vh;
    --fall-duration: 10.16s;
    --fall-delay: 2.613s;
  }
  .star:nth-child(30) {
    --star-tail-length: 7.26em;
    --top-offset: 64.63vh;
    --fall-duration: 6.211s;
    --fall-delay: 3.716s;
  }
  .star:nth-child(31) {
    --star-tail-length: 5.5em;
    --top-offset: 16.27vh;
    --fall-duration: 10.741s;
    --fall-delay: 7.501s;
  }
  .star:nth-child(32) {
    --star-tail-length: 6.9em;
    --top-offset: 32.59vh;
    --fall-duration: 11.414s;
    --fall-delay: 4.554s;
  }
  .star:nth-child(33) {
    --star-tail-length: 7.07em;
    --top-offset: 18.04vh;
    --fall-duration: 10.833s;
    --fall-delay: 6.724s;
  }
  .star:nth-child(34) {
    --star-tail-length: 6.28em;
    --top-offset: 58.32vh;
    --fall-duration: 6.111s;
    --fall-delay: 6.683s;
  }
  .star:nth-child(35) {
    --star-tail-length: 7.31em;
    --top-offset: 7.99vh;
    --fall-duration: 6.608s;
    --fall-delay: 1.704s;
  }
  .star:nth-child(36) {
    --star-tail-length: 7.02em;
    --top-offset: 93.42vh;
    --fall-duration: 11.609s;
    --fall-delay: 4.958s;
  }
  .star:nth-child(37) {
    --star-tail-length: 6.06em;
    --top-offset: 0.3vh;
    --fall-duration: 9.642s;
    --fall-delay: 4.175s;
  }
  .star:nth-child(38) {
    --star-tail-length: 5.29em;
    --top-offset: 85.67vh;
    --fall-duration: 7.922s;
    --fall-delay: 9.861s;
  }
  .star:nth-child(39) {
    --star-tail-length: 6.67em;
    --top-offset: 26.68vh;
    --fall-duration: 7.921s;
    --fall-delay: 8.009s;
  }
  .star:nth-child(40) {
    --star-tail-length: 5.99em;
    --top-offset: 26.59vh;
    --fall-duration: 9.593s;
    --fall-delay: 8.916s;
  }
  .star:nth-child(41) {
    --star-tail-length: 6.83em;
    --top-offset: 93.79vh;
    --fall-duration: 6.934s;
    --fall-delay: 0.456s;
  }
  .star:nth-child(42) {
    --star-tail-length: 7.35em;
    --top-offset: 85.63vh;
    --fall-duration: 6.349s;
    --fall-delay: 8.508s;
  }
  .star:nth-child(43) {
    --star-tail-length: 6.39em;
    --top-offset: 68.07vh;
    --fall-duration: 6.609s;
    --fall-delay: 0.253s;
  }
  .star:nth-child(44) {
    --star-tail-length: 5.2em;
    --top-offset: 38.62vh;
    --fall-duration: 11.589s;
    --fall-delay: 6.695s;
  }
  .star:nth-child(45) {
    --star-tail-length: 7.29em;
    --top-offset: 46.44vh;
    --fall-duration: 6.423s;
    --fall-delay: 9.257s;
  }
  .star:nth-child(46) {
    --star-tail-length: 5.18em;
    --top-offset: 12.69vh;
    --fall-duration: 9.714s;
    --fall-delay: 1.382s;
  }
  .star:nth-child(47) {
    --star-tail-length: 6.61em;
    --top-offset: 25.14vh;
    --fall-duration: 11.039s;
    --fall-delay: 1.698s;
  }
  .star:nth-child(48) {
    --star-tail-length: 7.42em;
    --top-offset: 97.17vh;
    --fall-duration: 9.668s;
    --fall-delay: 5.488s;
  }
  .star:nth-child(49) {
    --star-tail-length: 5.7em;
    --top-offset: 12.5vh;
    --fall-duration: 7.807s;
    --fall-delay: 1.337s;
  }
  .star:nth-child(50) {
    --star-tail-length: 6.25em;
    --top-offset: 58.83vh;
    --fall-duration: 10.396s;
    --fall-delay: 6.748s;
  }
  .star::before, .star::after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
  }
  .star::before {
    transform: rotate(45deg);
  }
  .star::after {
    transform: rotate(-45deg);
  }
  
  @keyframes fall {
    to {
      transform: translate3d(-30em, 0, 0);
    }
  }
  @keyframes tail-fade {
    0%, 50% {
      width: var(--star-tail-length);
      opacity: 1;
    }
    60%, 80% {
      width: 0;
      opacity: 0.7;
    }
    100% {
      width: 0;
      opacity: 0;
    }
  }
  @keyframes blink {
    50% {
      opacity: 0.6;
    }
  }